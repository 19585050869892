<template>
    <div class="houseCombo">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="filtrate">
                <div class="line">
                    <!-- <div class="kindName">套餐类型</div> -->
                    <div class="kindName">方案类型</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':spaceTypeValue==item.dictValue}" v-for="(item,i) in spaceTypeList" :key="i" @click="clickSpaceType(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="kindName">装修风格</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':styleValue==item.dictValue}" v-for="(item,i) in styleList" :key="i" @click="clickStyle(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="kindName">价格区间</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':priceValue==item.dictValue}" v-for="(item,i) in priceList" :key="i" @click="clickPrice(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
            </div>
            <div class="comboShow">
                <div class="empty" v-show="houseComboList.length==0">
                    <img src="../assets/imgs/goodsEmpty.png" />
                    <div>店家正在努力上新中~</div>
                </div>

                <!-- <div :class="{'comboItem':1, 'combo1':i==0,'combo2':i==1,'combo3':i==2}" v-for="(item,i) in houseComboList" :key="i" @click="toDetail(item)"> -->
                <div :class="{'comboItem':1, 'combo1':i%3==0,'combo2':i%3==1,'combo3':i%3==2}" v-for="(item,i) in houseComboList" :key="i" @click="toDetail(item)">
                    <div class="picArea">
                        <div class="bigPic">
                            <img :src="item.coverList[0]?item.coverList[0]:require('../assets/imgs/place25.png')"/>
                        </div>
                        <div class="smallPic">
                            <div class="inner">
                                <div class="item" v-for="(url,j) in item.coverList" :key="j">
                                    <!-- <img src="../assets/imgs/place26.png"/> -->
                                    <img :src="url"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="name">
                        <span class="comboName">{{item.housePackageName}}</span>
                        {{item.subtitle}}
                    </div>
                    <div class="comboPrice">
                        商城价：<span>￥{{item.totalPrice}}</span>
                    </div>
                </div>
                <!-- <div class="comboItem combo2">
                    <div class="picArea">
                        <div class="bigPic">
                            <img src="../assets/imgs/place27.png"/>
                        </div>
                        <div class="smallPic">
                            <div class="item" v-for="i in 4" :key="i">
                                <img src="../assets/imgs/place26.png"/>
                            </div>
                        </div>
                    </div>
                    <div class="name">
                        <span class="comboName">奶油风新潮世代高净套餐</span>
                        20㎡定制柜子+8件希曼全屋家具+10㎡轻奢护墙
                    </div>
                    <div class="comboPrice">
                        商城价：<span>￥35800</span>
                    </div>
                </div>
                <div class="comboItem combo3">
                    <div class="picArea">
                        <div class="bigPic">
                            <img src="../assets/imgs/place28.png"/>
                        </div>
                        <div class="smallPic">
                            <div class="item" v-for="i in 4" :key="i">
                                <img src="../assets/imgs/place26.png"/>
                            </div>
                        </div>
                    </div>
                    <div class="name">
                        <span class="comboName">奶油风新潮世代高净套餐</span>
                        20㎡定制柜子+8件希曼全屋家具+10㎡轻奢护墙
                    </div>
                    <div class="comboPrice">
                        商城价：<span>￥35800</span>
                    </div>
                </div> -->
            </div>

            <div class="pagi">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="totalNum">
                </el-pagination>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getHousePackageList__,getDictTypeList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            currentPage:1,
            houseComboList:[],
            totalNum:0,
            pageSize:3,
            spaceTypeList:[],//空间类型
            spaceTypeValue:"",
            styleList:[],//风格
            styleValue:"",
            priceList:[],//价格区间
            priceValue:"",
        }
    },
    methods: {
        toDetail(item){
            this.$router.push({
                path:"/houseComboDetail",
                query:{housePackageId:item.housePackageId}
            })
        },
        handleSizeChange(){},
        handleCurrentChange(){},
        // 获取全无套餐分页列表
        gainHousePackageList(){
            getHousePackageList__({
                page:this.currentPage,
                size:this.pageSize,
                priceRange:this.priceValue,
                spaceType:this.spaceTypeValue,
                style:this.styleValue,
            }).then(res=>{
                // console.log("全屋套餐分页列表",res);
                if(res.code==200){
                    res.data.rows.map(item=>{
                        item.coverList=item.coverStrs.split(",")
                    })
                    this.houseComboList=res.data.rows
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 查询字典数据
        gainDictList(){
            getDictTypeList({
                dictType:"SpaceType"
            }).then(res=>{
                // console.log("空间类型",res);
                if(res.code==200){
                    this.spaceTypeList=res.data;
                    // this.spaceTypeValue=this.spaceTypeList[0].dictValue;
                }
            })
            getDictTypeList({
                dictType:"GoodsStyle"
            }).then(res=>{
                // console.log("风格",res);
                if(res.code==200){
                    this.styleList=res.data.splice(0,10);
                    // this.styleValue=this.styleList[0].dictValue;
                }
            })
            getDictTypeList({
                dictType:"PriceRange"
            }).then(res=>{
                // console.log("价格区间",res);
                if(res.code==200){
                    this.priceList=res.data;
                    // this.priceValue=this.priceList[0].dictValue;
                }
            })
        },
        // 点击空间类型
        clickSpaceType(item){
            if(this.spaceTypeValue==item.dictValue){
                this.spaceTypeValue=""
            }else{
                this.spaceTypeValue=item.dictValue;
            }
            this.gainHousePackageList()
        },
        // 点击风格
        clickStyle(item){
            if(this.styleValue==item.dictValue){
                this.styleValue=""
            }else{
                this.styleValue=item.dictValue;
            }
            this.gainHousePackageList();
        },
        // 点击价格区间
        clickPrice(item){
            if(this.priceValue==item.dictValue){
                this.priceValue=""
            }else{
                this.priceValue=item.dictValue;
            }
            this.gainHousePackageList();
        }
    },
    created() {
        this.gainHousePackageList();
        this.gainDictList();
    },
}
</script>

<style lang="less" scoped>
.houseCombo{
    .filtrate{
        margin-top: 50px;
        .line{
            padding: 11px 22px;
            padding-left: 0;
            border-bottom: 1px solid rgba(237, 237, 237, 1);
            display: flex;
            align-items: center;
            .kindName{
                width: 121px;
                box-sizing: border-box;
                font-size: 14px;
                font-weight: 400;
                color: #333
            }
            &:last-child{
                border-bottom: none;
            }
            .subs{
                flex: 1;
                height: 100%;;
                display: flex;
                align-items: center;
                .item{
                    padding: 0 10px;
                    height: 25px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    margin-right: 20px;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        background: rgba(37, 126, 93, 1);
                        color: rgba(255, 255, 255, 1);
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .comboShow{
        margin-top: 60px;
        .comboItem{
            width: 100%;
            margin-bottom: 90px;
            &:last-child{
                // margin-bottom: 0;
            }
            &.combo1{
                .picArea{
                    background: rgba(222, 204, 180, 1);
                    .smallPic .item{
                        background: rgba(199, 175, 151, 1);
                    }
                }
            }
            &.combo2{
                .picArea{
                    background: rgba(194, 194, 194, 1);
                    .smallPic .item{
                        background: rgba(153, 148, 148, 1);
                    }
                }
            }
            &.combo3{
                .picArea{
                    background: rgba(197, 210, 219, 1);
                    .smallPic .item{
                        background: rgba(113, 137, 166, 1);
                    }
                }
            }
            .picArea{
                width: 100%;
                height: 679px;
                display: flex;
                align-items: center;
                .bigPic{
                    height: 100%;
                    width: 1051px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .smallPic{
                    height: calc(100% - 60px);
                    margin: 30px 0;
                    box-sizing: border-box;
                    flex: 1;
                    overflow: auto;
                    &::-webkit-scrollbar{
                        width: 0;
                    }
                    .inner{
                        min-height: 100%;
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        // justify-content: space-between;
                        .item{
                            width: 276px;
                            height: 138px;
                            margin-bottom: 20px;
                            background: rgba(199, 175, 151, 1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            border-radius: 6px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            img{
                                // max-width: 80%;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
            .name{
                margin-top: 30px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                .comboName{
                    margin-right: 30px;
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(158, 110, 62, 1);

                }
            }
            .comboPrice{
                margin-top: 17px;
                font-size: 24px;
                font-weight: 700;
                color: #333;
                &>span{
                    // font-size: 48px;
                    font-size: 40px;
                    font-weight: 900;
                    color: rgba(212, 48, 48, 1);
                }
            }
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            display: flex;
            justify-content: center;
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            display: block;
            // justify-content: center;
            text-align: center;
            &>img{
                width: 500px;
                margin-bottom: 10px;
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: #333;
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>